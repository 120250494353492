.Nav-bar {
  /* backdrop-filter: blur(10px); */
  /* background-color: pink; */

  background-color: var(--bg_color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;

  width: 100vw;
  height: 8vh;
  /* border-style: solid; */
  /* border-color: blue; */
  position: sticky;
  top: 0;

  letter-spacing: 0.125vw;
  font-family: "caslon";
  /* font-size: 1.5vw; */
  color: var(--primary_color);
  font-style: italic;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  /* width: 35vw; */
  text-transform: none;
}

.Nav-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* min-width: 350px; */
  /* border-style: solid;
  border-color: blue; */
}

.Coding-page {
  /* background-color: #282c34; */
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
}

.Section-container {
  width: 100%;
  /* background-color: pink; */
  margin: 20px;
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Project-container {
  /* background-color: pink; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* justify-content:; */
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 100px;

  flex-wrap: wrap;
}

.Job-history-container {
  /* background-color: pink; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Job-component {
  margin: 5px;

  width: 600px;
  /* margin: 20px; */
  display: flex;

  gap: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* width: fit-content; */
  background-color: var(--bg-color);
  /* outline-style: solid; */
  /* outline-color: red; */
}
.Skills {
  color: var(--primary_color);
}
.Job-image-container {
  /* margin-right: 40px; */
  /* outline-style: solid; */
  /* outline-color: red; */
  height: 150px;
  width: 150px;
}
.Job-image {
  width: fit-content;
  height: fit-content;
  width: 100%; /* or any custom size */
  height: 100%;
  object-fit: contain;

  /* height: 100%; */
  /* width: 100%; */
  /* object-fit: fit; */
}

.Job-title-description {
  /* outline-style: solid; */
  /* outline-color: red; */
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Job-labels {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.Job-company {
  margin: 4px;
  /* font-weight: 700; */
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  color: var(--primary_color);
  /* line-height: 2.23vw; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-end; */
  /* width: 35vw; */
  /* text-align: right; */
  letter-spacing: 2px;
  /* font-size: 1.6vw; */
}
.Job-title {
  margin: 4px;

  font-weight: 500;
  /* outline-style: solid; */
  /* outline-color: red; */
  font-family: "futura-pt", sans-serif;
  color: var(--primary_color);
  /* line-height: 2.23vw; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-end; */
  /* width: 35vw; */
  /* text-align: right; */
  letter-spacing: 2px;
  /* font-size: 1.6vw; */
}
.Date {
  margin: 4px;
  color: var(--primary_color);
}
.Job-description {
  margin: 4px;
  text-align: start;
  font-family: "futura-pt", sans-serif;
  line-height: 25px;
  font-size: 14px;
  color: var(--primary_color);
  /* outline-style: solid; */
  /* outline-color: red; */
  align-items: flex-start;
}
.Connector-line {
  margin: 3px;
  height: 38px;
  border-left: solid lightgray;
}

@media only screen and (max-width: 740px) {
  .Job-component {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  .Job-title-description {
    align-items: center;
    width: 90vw;
  }
  .Job-description {
    text-align: center;
    max-width: 400px;
  }
  .Job-labels {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 940px) {
  .Inspo-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
  .Inspo-written-content {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .Inspo-description {
    width: 75vw;
    font-size: 15px;
  }
}

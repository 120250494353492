.Hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.TitleWithLine {
  /* margin-top: 3vh;
  margin-bottom: 3vh; */
  height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border-style: solid; */
  /* border-color: blue; */
}

.vl {
  margin-left: 2vw;
  margin-right: 2vw;
  border-left: 1px solid var(--primary_color);
  height: 7vh;
}

#title {
  /* border-style: solid; */
  /* border-color: blue; */
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  color: var(--primary_color);
  line-height: 2.23vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35vw;
  text-align: right;
  letter-spacing: 0.39vw;
  font-size: 1.6vw;
}
#description {
  letter-spacing: 0.125vw;
  font-family: "caslon";
  font-size: 1.5vw;
  color: var(--primary_color);
  font-style: italic;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  width: 35vw;
  text-transform: none;
}

.Hero-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Bottom-icon-container {
  /* outline-style: solid; */
  /* outline-color: blue; */
  height: 25vh;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon-svg g {
  height: 16vh;
  width: auto;
  fill: var(--primary_color);
}

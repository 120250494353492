:root {
  --bg_color: white;
  --primary_color: rgb(34, 34, 34);
  --link_color: navy;
}
* {
  scroll-margin-top: 100px;
}

[data-theme="dark"] {
  --bg_color: #292c32;
  --primary_color: #c5cfd8;
  --link_color: cyan;
}
/* * {
  transition: all 0.3s ease-in-out;
} */
body {
  /* margin: 0; */
  font-family: "JetBrains Mono", monospace;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

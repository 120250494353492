/* inspired by https://codesandbox.io/s/l5wnpyw6j7?file=/src/index.js */

.Selectable-underline {
  /* border-style: solid; */
  /* border-color: green; */
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  font-size: calc(10px + 2vmin);
}

.active {
  border-bottom: 1px solid var(--primary_color);
  /* cursor: pointer; */
  /* display: inline-block; */
  /* position: relative; */
  /* padding-bottom: 10px; */
}

.Selectable-underline:after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  display: block;
  margin-top: 10px;
  right: 0;
  /* background: salmon; */
  background: var(--primary_color);
  transition: width 0.3s ease;
}

.Selectable-underline:hover:after {
  width: 100%;
  left: 0;
  background: var(--primary_color);
}

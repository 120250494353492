.Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

hr {
  width: 98vw;
  border: 0;
  border-top: 1px solid #bcbcbc;
}
.Footer-header {
  text-align: center;
  color: var(--primary_color);
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 24px;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 4px;
}
.Social-media-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  max-width: 250px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.Social-media-button {
  padding-left: 10px;
  padding-right: 10px;
}

.Footer-logo {
  margin-top: 20px;
  margin-bottom: 20px;
  fill: #bbbbbb;
  height: 20px;

  min-height: 40px;
  width: auto;
  /* fill: black; */
}

.Footer-bottom-text {
  font-size: 11px;

  color: #bbbbbb;
}
a {
  color: var(--primary_color);
  text-decoration: none;
}

a:hover {
  color: #585b5c;
  text-decoration: none;
  cursor: pointer;
}

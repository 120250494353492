.Topic-container {
  /* border-style: solid; */
  /* border-color: green; */
  min-height: 60vh;
  width: 80vw;
  max-width: 1200px;
  display: flex;
  flex-direction: Row;
  align-items: center;
}

.Topic-image-container {
  background-color: #f1f1fa;

  /* border-style: solid; */
  /* border-color: blue; */
  height: 60vh;
  width: 60%;
  overflow: hidden;
}

.Topic-words-container {
  /* border-style: solid; */
  /* border-color: red; */
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  height: auto;
}
.Topic-title {
  margin: 0px;
  /* border-style: solid; */
  /* border-color: red; */
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  line-height: 2.23vw;
  letter-spacing: 10px;
  color: var(--primary_color);
  line-height: 2.23vw;
  font-size: 1.6vw;
}
.Uc-container {
  width: auto;
  height: auto;
  position: relative;
  margin: 30px;
  /* background-color: blue; */
}
.Topic-uc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.Topic-description {
  /* border-style: solid; */
  /* border-color: red; */
  margin-left: 25px;
  margin-right: 25px;
  font-family: "caslon";
  font-size: 1.5vw;
  font-weight: 500;
  line-height: 40px;
  color: var(--primary_color);
}

.Learn-more-container {
  transition: all 0.3s;

  /* border-style: solid; */
  /* border-color: green; */
  display: flex;
  flex-direction: Row;
  align-items: center;
  justify-content: center;
}
.Learn-more-container:hover {
  transform: translate(0px, -5px);
}

.Topic-icon g {
  fill: var(--primary_color);
  /* border-style: solid;
  border-color: green; */
  height: 50px;
  width: auto;
}

.Topic-arrow {
  /* border-style: solid; */
  /* border-color: green; */
  color: var(--primary_color);
  size: 80;
}
.Description-container {
  /* border-style: solid; */
  display: flex;
  flex-grow: 1;
  /* border-color: purple; */
}

@media only screen and (max-width: 940px) {
  .Topic-container {
    margin-top: 8vh;
    width: 70v;
    flex-direction: column;
    align-items: center;
  }

  .Topic-image-container {
    height: 40vh;
    width: 100%;
  }
  .Topic-words-container {
    padding-left: 0px;
  }

  .Topic-title {
    font-size: 23px;
  }
  .Topic-description {
    font-size: 15px;
  }
}

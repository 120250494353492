.Art-page {
  /* background-color: #282c34; */
  /* border-style: solid; */
  /* border-color: green; */
  user-select: none;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* width: 80vw; */
  /* font-size: calc(10px + 2vmin); */
}

.Main-header {
  /* background-color: #282c34; */
  /* border-style: solid; */
  /* border-color: green; */
  user-select: none;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.Image-container {
  background-color: #f1f1fa;
  height: 60vh;
  width: 60vw;
  /* border-style: solid; */
  /* border-color: green; */
  overflow: hidden;
}

.Topic-section {
  /* border-style: solid; */
  /* border-color: blue; */
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

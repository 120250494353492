.Music-page {
  /* background-color: #282c34; */
  /* border-style: solid; */
  /* border-color: green; */
  user-select: none;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  /* width: 80vw; */
  /* font-size: calc(10px + 2vmin); */
}
.Music-section-title {
  color: var(--primary_color);
  margin: 10px;
  /* font-weight: 700; */
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  /* line-height: 2.23vw; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-end; */
  /* width: 35vw; */
  /* text-align: right; */
  letter-spacing: 2px;
  font-size: 23px;
}

.Album-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: pink; */
  width: 100vw;
  min-height: 100vh;
}

.Album-section-container {
  /* background-color: orange; */
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 800px;
  width: 90vw;
}

.Album-description-section {
  color: var(--primary_color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: 100%;
  text-align: left;

  /* background-color: pink; */
}

.Album-holder {
  /* background-color: red; */
  width: 60%;
}
.Album-title {
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  /* line-height: 2.23vw; */
  letter-spacing: 10px;
  color: var(--primary_color);
  /* line-height: 2.23vw; */
  /* font-size: 1.6vw; */
}

.Section-title {
  margin: 10px;
  /* font-weight: 700; */
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  color: var(--primary_color);
  /* line-height: 2.23vw; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-end; */
  /* width: 35vw; */
  /* text-align: right; */
  letter-spacing: 2px;
  font-size: 23px;
}

.hidden {
  opacity: 0%;
  transform: translate(0, 50px);
  filter: blur(0px);
  transition: all 0.8s;
}

.show {
  opacity: 1;
  transform: translate(0, 0) rotate(0deg);
  filter: blur(0);
}

@media only screen and (max-width: 740px) {
  .Album-section-container {
    flex-direction: column;
    justify-content: center;
  }

  .Album-holder {
    width: 80%;
    max-width: 400px;
    align-items: flex-start;
  }
  .Album-description-section {
    width: 80%;
    max-width: 400px;
    align-items: flex-start;
    padding: 0;
    padding-top: 20px;
    /* background-color: pink; */
    /* min-width: 400px; */
  }
}
